import { useEffect, useState } from "react";
import "./Publications.css";
import { useNavigate } from "react-router-dom";
import { base_URL } from "../../../Middleware/config";
import axios from "axios";
import { getNepaliDate } from "../../../utils";

export default function Publication() {
  const [publicationData, setPublicationData] = useState([]);
  const changeLocation = useNavigate();

  const getPublication = async () => {
    await axios
      .get(`${base_URL}/publication/search?search=&limit=15&offset=0`)
      .then((response) => {
        setPublicationData(response.data.body);
      });
  };

  useEffect(() => {
    getPublication();
  }, []);

  if (!publicationData) {
    return <h4>No Pulications!</h4>;
  }

  return (
    <ul className="pub-list">
      {publicationData.map((p) => {
        return (
          <li className="card" key={p.id} onClick={() => changeLocation("/publication")}>
            <p className="pub-text">{p.description}</p>
            <p className="pub-date">
              <span>Published</span> {getNepaliDate(p.publishDate)}
            </p>
          </li>
        );
      })}
    </ul>
  );
}
