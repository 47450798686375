import React from 'react';
//import { UnderConstructionPage } from '../../../Middleware/helper.js';

const StudentsUnion = () => {
  return (
    <>
      <header className="text-center m-2">
        <h1>Students Union</h1>
      </header>
      <section>
        <article>
        <div className="underConstructionPage">
          <h1>2079 Elections still to be conducted.</h1>
        </div>
        </article>
      </section>
    </>
  );
}

export default StudentsUnion