import axios from "axios";
import { base_URL } from "../../../Middleware/config";
import { useState, useEffect } from "react";
import "./NewsEvents.css";
import { useNavigate } from "react-router-dom";
import { getNepaliDate } from "../../../utils";

export function NewsEvents() {
  const [galleryContent, setGalleryContent] = useState([]);
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/gallery")
  }

  const getImages = async () => {
    await axios
      .get(`${base_URL}/gallery/search?search=&limit=3&offset=0`)
      .then((result) => {
        let data = result.data.body;
        let gallery = [];
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let galleryImage = {};
            galleryImage.id = data[i].id;
            galleryImage.title = data[i].title;
            galleryImage.date = data[i].date;
            galleryImage.description = data[i].description;
            galleryImage.images = data[i].images.toString().split(",");
            gallery.push(galleryImage);
          }
          setGalleryContent(gallery);
        }
      });
  };

  useEffect(() => {
    getImages();
  }, []);

  return <NewsItemNew galleryItems={galleryContent} onClick={handleClick} />;
}



export function NewsItemNew(props) {
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {props.galleryItems.map((item, index) => {
        return (
          <div key={index} className="news-events-item" onClick={props.onClick}>
            <div className="news-thumbnail">
              <img
                className="news-image"
                src={`${base_URL}/images/${item.images[0]}`}
                alt={item.title}
                onContextMenu={handleContextMenu}
              />
            </div>

            <div>
              <p className="news-dt">{`Date: ${getNepaliDate(item.date)}`}</p>
              <h5 style={{ color: "var(--primary-color)", textAlign: "left" }}>{item.title}</h5>
              <p className="news-text-content line-clamp align-left">
                {item.description}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
}
