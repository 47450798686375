import React from 'react'
import '../lifeCommon.css'
import './Library.css';
import { useState, useEffect, useRef } from 'react';
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";

// import noImageFound from "../../../images/no-image-found.png";
import lib1 from "../../../images/library/library1.jpeg";
import lib2 from "../../../images/library/library2.jpeg";
import lib3 from "../../../images/library/library3.jpeg"

const Library = () => {
  return (
    <>
      <header className="main-heading m-2">
        <h1>Library</h1>
      </header>
      <section className='trmc-page'>
        <div className="trmc-content">
          <LibrarySlider />
          <div className="trmc-text">
            <p>The campus has a library which is a Regional Library of TU. It has strength of around 47,330 textbooks, 28090 reference books and 6740 other references. The library runs in three shifts (i.e. Morning, Day and Evening) for the convenience of the students. There is a thesis section which consists of Masters level theses completed by the students of this campus. There is availability of national (4) and international (2) journals in library.</p>
          </div>
        </div>
      </section>
    </>
  )
}

const LibrarySlider = () => {
  const dispImages = [lib1, lib2, lib3];
  const [currentImg, setCurrentImg] = useState(0);
  const imgRef = useRef(null);
  const indicatorRef = useRef(null);
  const currentImgRef = useRef()

  currentImgRef.current = currentImg

  useEffect(() => {
    let dots = Array.from(indicatorRef.current.children);
    dots.forEach((d, i) => {
      i === currentImg ? d.classList.toggle('active') : d.classList.remove('active')
    })

  }, [currentImg])

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentImgRef.current >= 0) {
        setCurrentImg(prev => prev + 1)
        if (currentImgRef.current === dispImages.length - 1) {
          setCurrentImg(0)
        }
      }
    }, 4000)
    return () => clearInterval(interval)
  }, [dispImages.length])

  const prevHandler = (e) => {
    currentImg === 0 ? setCurrentImg(dispImages.length - 1) : setCurrentImg(currentImg - 1)
  }
  const nextHandler = (e) => {
    currentImg === dispImages.length - 1 ? setCurrentImg(0) : setCurrentImg(currentImg + 1)
  }
  return (
    <div className="lib-slider">
      <img ref={imgRef} src={dispImages[currentImg]} className="lib-img" alt="" />
      <FiChevronLeft className="lib-prev" onClick={prevHandler} />
      <FiChevronRight className="lib-next" onClick={nextHandler} />
      <div ref={indicatorRef} class="lib indicator">
        {dispImages.map((img, index) => {
          return index === 0 ? <span key={index} class="d active"></span> :
            <span key={index} class="d"></span>
        })}
      </div>
    </div>)
}

export default Library

