import React from 'react'
import "./Cafetria.css";
import '../lifeCommon.css'
import img1 from "../../../images/Canteen.jpg"


const Cafetria = () => {
  return (
    <>
    <header className="main-heading m-2">
      <h1>Cafetria</h1>
    </header>
    <section className='trmc-page'>
      <div className="trmc-content">
        <img className="trmc-img" src={img1} alt="Image" />
        <div className="trmc-text">
          <p>The cafeteria at TRMC is well set and well accommodated which allows all its clients (teachers, employees, students and even out comers) to fully get involved in feeding and eating. All its services and facilities are with easy access that lets them have opportunity to pursue their interest and wish wile being within campus premises. Mainly during the break time, all stakeholders gather to quench their thirst of eating and drinking with all sorts of refreshments. The cafeteria of TRMC which situated at the east- south corner of main building is the best example of caterer for its all services.</p>
        </div>
      </div>
    </section>
  </>
  )
}

export default Cafetria