import React from 'react'
import stricture from '../../../../images/Organizational-Structure.png'; 
import { Image_URL } from '../../../../Middleware/config';
import './chart.css'

const OrginizationChart = () => {
  return (
    <div className = 'chart'>
        <h3>Orginization Chart Thakuram Multiple Campus</h3>
        <img className="tableImage" src={stricture !== 'N/A' || '' ?  `${stricture}` : `${Image_URL}/${'noPic.png'}`} alt="No File"/>
    </div>
  )
}

export default OrginizationChart