import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_URL } from "../../Middleware/config";
import "./StaffPage.css";
import { handleImageClick } from "../../Middleware/helper";

export default function TeachingStaff() {
  return (
    <>
      <div className="staff-heading">Teaching Staff</div>
      <div className="staff-container">
        <TeachingStaffDetail />
      </div>
    </>
  );
}

function TeachingStaffDetail(props) {
  const [teachingStaff, setTeachingStaff] = useState([]);

  const TeachingStaffDetail = async (id, keyId) => {
    await axios
      .get(`${base_URL}/staffs/searchEmpByType?id=${id}&keyId=${keyId}`)
      .then((getData) => {
        setTeachingStaff(getData.data.body); // multiple data received loop required
      });
  };

  useEffect(() => {
    TeachingStaffDetail(1,3);
  }, []);
  return (
    <>
      {teachingStaff.map((ts) => {
        const avatar =
          ts.profilePic !== "N/A"
            ? `${base_URL}/images/${ts.profilePic}`
            : "https://avatars.mds.yandex.net/i?id=a4137a3813859a900a99b4e1c9b80e4d9af05003-8220238-images-thumbs&n=13";
        return (
          <div key={ts.id} className="staff-detail-card">
            <div className="avatar">
              <img
                style={{ display: "inline-block", margin: "10px" }}
                src={avatar}
                className="staff-picture"
                alt=""
                onContextMenu={handleImageClick}
              />
            </div>
            <div className="staff-information">
              <Text content={`Name: ${ts.fullName}`} />
              <Text content={`Position: ${ts.designation}`} />
              {/* <Text content={ts.committeeId === 1 ? `Position: ${ts.designation} (${ts.committee})` : `Position: ${ts.designation}`} /> */}
              <Text content={`Department: ${ts.department}`} />
              {/* <Text content={`Experience: ${ts.experience === 'N/A' ? '' : ts.experience}`} /> */}
              {/* <span>{`${ts.about}`}</span> */}
            </div>
          </div>
        );
      })}
    </>
  );
}

function Text({ content }) {
  return <p className="text">{content}</p>;
}

