import React from "react";

export default function SearchBox(props) {
  return (
    <input
      placeholder={props.placeholder}
      type="text"
      name="search"
      value={props.value}
      onChange={props.onChange}
    />
  );
}
