import axios from "axios";
import React, { useState, useEffect } from "react";
import { base_URL } from "../../../../Middleware/config";
import { ProfileCardItem } from '../../../../Middleware/helper';
import "./mgmtCommittee.css";
import Spinner from "../../../../component/Spinner/Spinner";

const ManagementCommittee = () => {
  const [isLoading, setIsLoading] = useState(null); // track spinner
  const [committeeData, setCommitteeData] = useState([]);

  const getCommitteeMember = async () => {
    setIsLoading(true);
    var committeeIds = await axios.get(`${base_URL}/staffsStricture/`).then((getData) => {
      return getData.data.body[0].mgmtCommitteeIds.split(',');
    });

    await axios.post(`${base_URL}/staffs/getStricture`, committeeIds).then((getData) => {
      setCommitteeData(getData.data.body);
    });
    setIsLoading(false);
    // await axios
    //   .post(`${base_URL}/staffs/searchCommittee`, chiefId)
    //   .then((getData) => {
    //     setCommitteeData(getData.data.body);
    //   });
  };
 
  useEffect(() => {
    getCommitteeMember();
  }, []);
  
  return (
    <>
      <header className="text-center mb-1 mgt-com-header">
        <h1>Campus Management Committee</h1>
      </header>
      <section className="mgt-com-pg">
      <p>This campus has been functioning as a decentralized unit since 2057 BS. As per TU rules and regulations, there is a Campus Management Committee (CMC) under the chairmanship of the Campus Chief. The Management Committee consists of the representatives of parents, social activists, educationists and other stakeholders.</p>
        <article className="mgt-com-container">
          {committeeData.map((comData) => {
             return <ProfileCardItem data={comData} />;
            // return <CommitteeItem data={comData} />;
          })}
        </article>
      </section>
      <>{isLoading === true ? <Spinner>Loading...</Spinner> : ''}</>
    </>
  );
};
export default ManagementCommittee;
