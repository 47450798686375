import axios from "axios";
import React, { useState, useEffect } from "react";
import { base_URL } from "../../../Middleware/config";
import "./EmployeeAssociation.css";
import { Image_URL } from "../../../Middleware/config";

const EmployeeAssociation = () => {
  // const aboutAssociation = "TRIBHUVAN UNIVERSITY EMPLOYEES ASSOCIATION (TUEA) was founded on 14 Baisakh 2047 BS in Kathmandu, which was for the first time,registered with the Kathmandu District Administration Office. But TRMC Unit of association was established in Birgunj on 2048 BS. Initially, the association was formed with an objective of safeguarding of professional rights and well-being of all its members. \n The Tribhuvan University Employees Association (TUEA) in TRMC Unit represents approximately 70 administrative and supports them regarding their welfare, responsibility, right, promotion etc. within campus premises. It has also run an employee welfare fund to help the needy of its members by collecting some amount of money month wise which will be returned after their retirements. The association has role in creating an environment in the campus because of which all teachers do academic work., employees do administrative work, examination etc. They facilitate many activities as well as research work within campus premises. In conclusion, issues-no matter either academic or non-academic are directly and indirectly concerned with TUEA.";

  const [associationData, setAssociationData] = useState([]);

  const associationId = [12, 13, 18, 19, 20, 21];

  const getAssociationMember = async () => {
    await axios
      .post(`${base_URL}/staffs/searchCommittee`, associationId)
      .then((getData) => {
        setAssociationData(getData.data.body);
      });
  };
  useEffect(() => {
    getAssociationMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="em-association-pg">
      <h2 className="main-heading m-1">Tribhuvan University Employee Association</h2>
      {/* <p className="mt-1">{aboutAssociation}</p> */}
      <p className="mt-1">TRIBHUVAN UNIVERSITY EMPLOYEES ASSOCIATION (TUEA) was founded on 14 Baisakh 2047 BS in Kathmandu, which was for the first time,registered with the Kathmandu District Administration Office. But TRMC Unit of association was established in Birgunj on 2048 BS. Initially, the association was formed with an objective of safeguarding of professional rights and well-being of all its members. <br/> The Tribhuvan University Employees Association (TUEA) in TRMC Unit represents approximately 70 administrative and supports them regarding their welfare, responsibility, right, promotion etc. within campus premises. It has also run an employee welfare fund to help the needy of its members by collecting some amount of money month wise which will be returned after their retirements.<br/> The association has role in creating an environment in the campus because of which all teachers do academic work., employees do administrative work, examination etc. They facilitate many activities as well as research work within campus premises. In conclusion, issues-no matter either academic or non-academic are directly and indirectly concerned with TUEA.</p>
      <h2 className="sub-heading">TUEA Thakur Ram Multiple Campus</h2>
      <section className="em-content">
        {associationData.map((comData) => {
          return <EmployeeItemCard data={comData} />;
        })}
      </section>
    </div>
  );
};

function EmployeeItemCard(props) {
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return props.data.map((com) => {
    return (
      <div key={com.id} className="em-item card">
        {com.profilePic === "N/A" ? (
          <div className="text-center profilepic">
            <img
              src={`${Image_URL}/${"noPic.png"}`}
              height={100}
              width={100}
              alt=""
              onContextMenu={handleContextMenu}
            />
          </div>
        ) : (
          <div className="text-center profilepic">
            <img
              src={`${Image_URL}/${com.profilePic}`}
              height={100}
              width={100}
              alt=""
              onContextMenu={handleContextMenu}
            />
          </div>
        )}
        <p>
          <span className="label-em">Name</span>:&nbsp; {com.fullName}
        </p>
        <p>
          <span className="label-em">Department</span>:&nbsp; {com.department}
        </p>
        <p>
          <span className="label-em">Designation</span>:&nbsp; {com.designation}
        </p>
        <p>
          <span className="label-em">Committee</span>:&nbsp; {com.committee}
        </p>
      </div>
    );
  });
}

export default EmployeeAssociation;
