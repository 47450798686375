import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Gallery } from "../../pages/Gallery/Gallery";
import AboutPage from "../../pages/AboutPage/AboutPage";
import CampusChief from "../../pages/AboutPage/subInfos/ChampusChief/CampusChief";
import ExecutiveCommittee from "../../pages/AboutPage/subInfos/Committee/executiveCommittee";
import ManagementCommittee from "../../pages/AboutPage/subInfos/Committee/mgmtCommittee";
import Strategies from "../../pages/AboutPage/subInfos/Strategies/Strategies";
import OrginizationChart from "../../pages/AboutPage/subInfos/chart/chart";
import EmployeeAssociation from "../../pages/Association/EmployeesAssociation/EmployeeAssociation";
import StudentsUnion from "../../pages/Association/StudentsUnion/StudentsUnion";
import TeachersAssociation from "../../pages/Association/TeachersAssociation/TeachersAssociation";
import BoysHostel from "../../pages/LifeAtTRMC/BoysHostel/BoysHostel";
import GirlsHostel from "../../pages/LifeAtTRMC/GirlsHostel/GirlsHostel";
import Cafetria from "../../pages/LifeAtTRMC/Cafetria/Cafetria";
import SportsAndHealth from "../../pages/LifeAtTRMC/SportsAndHealth/SportsAndHealth";
import StudentWelfare from "../../pages/LifeAtTRMC/StudentWelfare/StudentWelfare";
import Library  from "../../pages/LifeAtTRMC/TrmcLibrary/Library";
import ContactPage from "../../pages/ContactPage/ContactPage";
import HomePage from "../../pages/HomePage/HomePage";
import Academic from "../../pages/Academic/Academic";
import CoursePage from "../../pages/CoursePage/CoursePage";
import Notice, { NoticeMain } from "../../pages/Notice/Notice";
import QAA from "../../pages/QAA/QAA";
import { AnimatePresence } from "framer-motion";
import PublicationPage from "../../pages/PublicationPage/PublicationPage";
import NonTeachingStaff from "../../pages/StaffPage/NonTeachingStaff";
import TeachingStaff from "../../pages/StaffPage/TeachingStaff";
import KeyStaff from "../../pages/StaffPage/KeyStaff";


export default function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />

        <Route path="/chiefMessage" element={<CampusChief />} />
        <Route path="/Strategies" element={<Strategies />} />
        <Route path="/mgmtCommittee" element={<ManagementCommittee />} />
        <Route path="/executiveCommittee" element={<ExecutiveCommittee />} />
        <Route path="/chart" element={<OrginizationChart />} />

        <Route path="/teachersAssociation" element={<TeachersAssociation />} />
        <Route path="/employeeAssociation" element={<EmployeeAssociation />} />
        <Route path="/studentUnion" element={<StudentsUnion />} />
        <Route path="/stuWelfareUnit" element={<StudentWelfare />} />
        <Route path="/cafetria" element={<Cafetria />} />
        <Route path="/sports" element={<SportsAndHealth />} />
        <Route path="/gHostel" element={<GirlsHostel />} />
        <Route path="/bHostel" element={<BoysHostel />} />
        <Route path="/library" element={<Library/>} />

        <Route path="/contact" element={<ContactPage />} />
        <Route path="/programs" element={<Academic />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/keyStaff" element={<KeyStaff />} />
        <Route path="/staff" element={<TeachingStaff />} />
        <Route path="/nonTeachingsstaff" element={<NonTeachingStaff />} />
        <Route path="/courses" element={<CoursePage />} />
        <Route path="/publication" element={<PublicationPage />} />
        <Route path="/qaa" element={<QAA />} />
        <Route path="/notice" element={<Notice />}>
          <Route index element={<NoticeMain />} />
          <Route path="admission" element={<NoticeMain />} />
          <Route path="administration" element={<NoticeMain />} />
          <Route path="exam" element={<NoticeMain />} />
          <Route path="downloads" element={<NoticeMain />} />
        </Route>
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </AnimatePresence>
  );
}
