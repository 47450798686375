import "./Marquee.css";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";



export default function Marquee(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const textRef = useRef(null);
  const handleClick = () => navigate("/notice")

  useEffect(() => {

    const interval = setInterval(() => {
      textRef.current.style.webkitAnimationPlayState = "paused"
      setCurrentIndex((prevState) => {
        let newState = prevState !== props.data.length - 1 ? prevState + 1 : 0
        textRef.current.style.webkitAnimationPlayState = "running"

        return newState
      });
    }, 15000)

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  return (
    <section className="marquee">
      <p ref={textRef} onClick={handleClick}>{
        props.data[currentIndex].description
      }</p>

    </section>
  );
}
