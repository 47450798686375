import React from "react";
import "./Academic.css";

export default function Academic() {
  return (
    <section className="academic-pg">
      <div>
        <h2>Academics</h2>
        <p>
          The campus runs a number of academic programs at Bachelor and Masters
          level in four streams viz. Education, Management, Humanities & Social
          Sciences as well as Science & Technology, to cater the educational
          needs of this arena. In previous years, two subjects at masters level
          viz. M.Ed. in Nepali and Population education were launched.
          Accordingly, Meteorology subject is added in Bachelor program. The
          campus has managed Masters level programs as mentioned above 4 years
          academic programs like Bachelor of Business Administration (BBA)
          Programs & Bachelor in Information Technology (BIT)with its internal
          source. The campus is in the process to start BIM, B.Sc.IT and LLB
          programs in the coming year.
        </p>
        {/* <h4>Pedagogical Module</h4>
                <p>The Campus has been carrying out the academic programs with certain pedagogical approaches those are in need of further revision for the collaborative learning process. An approach of natural critical pedagogical module will be adopted for designing both intra and inter faculty teaching learning tools ensuring participation of students, faculties and stakeholders.</p>
                <h4>Research Activities</h4>
                <p>As the campus has been carrying out teaching cum research activities within its academic terrains, PKMC gives greater emphasis on research-based teaching activities. Its autonomous discretionary status demands that the campus revisits its academic environment by shifting its focus more on to research so as to produce critical research-minded and innovative scholars who can create new body of knowledge suitable for social, cultural, political and corporate world. With this view in mind, the campus has set up a Research Management Cell (RMC) to support both pure academic researches and researches aiming at innovations as a way to support the community, society, government, corporate and industrial world engaging students, faculties and collaborative researchers.</p>
                <h4>Autonomous Status</h4>
                <p>PKMC has chosen the path to autonomy following the initiatives of higher education reform project realizing the necessity of educational reform and revitalization to make its programs a competitive and qualitative. The major goal of the campus is to extend academic avenues and earn an accredited status with a recognition of standard university in future.</p> */}
      </div>
      <ol>
        <div style={{ width: "90%", margin: "0 auto" }}>
          <li className="root-item">
            Our Bachelor Programs
            <ol type="a">
              <li className="sub-item">
                Bachelors in Science
                <ul type="i">
                  <li>Bachelor of Science (B.Sc. , 4 Years Program)</li>
                  <li>
                    Bachelor In Information Technology (BIT, 4 years Semester
                    Program)
                  </li>
                </ul>
              </li>
              <li className="sub-item">
                Bachelors in Management
                <ul type="i">
                  <li>Bachelor of Business Studies (BBS 4 Years)</li>
                  <li>
                    {" "}
                    Bachelor of Business Administration (BBA, 4 Years Semester
                    Program)
                  </li>
                </ul>
              </li>
              <li className="sub-item">
                Bachelors in Arts
                <ul type="i">
                  <li>Bachelor of Arts(BA, 4 Years)</li>
                </ul>
              </li>
              <li className="sub-item">
                Bachelors in Education
                <ul type="i">
                  <li>Bachelor in Education(B.Ed., 4 Years)</li>
                </ul>
              </li>
              <li className="sub-item">
                Bachelors of Business Management
                <ul type="i">
                  <li>Bachelors of Business Management(BBM, 4 Years)</li>
                </ul>
              </li>
            </ol>
          </li>
          <li className="root-item">
            Our Master Programs
            <ol type="a" className="sub-item">
              <li className="sub-item">
                Master in Business Studies(MBS)
                <ul type="i">
                  <li>Master in Business Studies(MBS)</li>
                </ul>
              </li>
              <li className="sub-item">
                Master of Arts (MA)
                <ul type="i">
                  <li>Master of Arts (MA) in Rural Development</li>
                  <li>Master of Arts (MA) in Political Science</li>
                  <li>Master of Arts (MA) in Sociology</li>
                  <li>Master of Arts (MA) in History</li>
                </ul>
              </li>
              <li className="sub-item">
                Master in Education(M.Ed.)
                <ul type="i">
                  <li>Master in Education(M.Ed.) in Nepali</li>
                  <li>Master in Education(M.Ed.) in English</li>
                  <li>Master in Education(M.Ed.) in Health</li>
                  <li>Master in Education(M.Ed.) in Population</li>
                  <li>Master in Education(M.Ed.) in EPM</li>
                  <li>Master in Education(M.Ed.) in Math</li>
                </ul>
              </li>
            </ol>
          </li>
        </div>
      </ol>
      {/* <a style={{ fontSize: "1em" }} href="https://pkcampus.edu.np/academic-programs">Academic Programmes Padma Kanya Multiple Campus(pkcampus.edu.np)</a> */}
    </section>
  );
}
