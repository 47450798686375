import React from "react";
import mission from "../../../../images/mission.png";
import vision from "../../../../images/vision.jpg";
//import goles from "../../../../images/goles.jpg";
import { Image_URL } from "../../../../Middleware/config";
import "./Strategies.css";

const Strategies = () => {
  return (
    <div className="strategies-pg">
      <div>
        <h2 className="mt-1 mb-2">Vision, Mission and Goal</h2>
        <p className="strat-main-text mt-2 mb-3">The major priority of this campus is to develop it as a leading academic institution with the development of physical infrastructure, equipped with modern technological devices for quality education to cater the social, economic and cultural needs of the people of the Central Terai Region of Nepal.</p>
      </div>
      <div className="strategies-item-container">
        <h4 className="strat-header center">OurMission</h4>
        <div className="mission">
          <div className="strat-img">
            <img
              src={
                mission !== "N/A" || ""
                  ? `${mission}`
                  : `${Image_URL}/${"noPic.png"}`
              }
              alt="No File"
            />
          </div>

          <section style={{ textAlign: "justify" }} className="strat-text">
            <strong style={{ color: "#555" }}>TRMC is a leading academic institution has its own mission to achieve in future.<br /> They are allocated as follows:</strong>
            <div>
              <ul style={{ marginLeft: "3rem" }}>
                <li>To develop TRM campus a leading academic institution (Proposed Birgunj University) of Nepal.</li>
                <li>Practical and modern approach of study.</li>
                <li>To make sure that the students do not memorize and learn but understand and enjoy studying.</li>
                <li>To impart abroad and balanced education through national and international curriculum.</li>
                <li>To have respect for self, family and community.</li>
                <li>To keep alive each child's natural curiosity and enthusing for learning.</li>
              </ul>
            </div>
          </section>

        </div>
        <h4 className="strat-header center">Vision & Goals</h4>
        <div className="vision">
          <div className="strat-img">
            <img
              className="tableImage"
              src={
                vision !== "N/A" || ""
                  ? `${vision}`
                  : `${Image_URL}/${"noPic.png"}`
              }
              alt="No File"
            />
          </div>

          <section className="strat-text">
            <strong style={{ color: "#555" }}>TRMC is another institution of many smaller academic wings has the following explicit visions:</strong>

            <ul style={{ marginLeft: "3rem" }}>
              <li>To develop TRM campus as a center of excellence for qualitative, scientific and life-supportive education</li>
              <li>To prepare competent global citizens together with enabling them to understand the distinct socio-cultural tradition and family values.</li>
              <li>To become one of the inspiring educational institutes of the nation through laying strong foundation of practical knowledge, discipline, professionalism and commitment to lead change within a culture of excellent teaching and learning.</li>
              <li>To eradicate the stress study and adapt fun in teaching and learning</li>
              <li>We imagine a world where education inspires the students to do wonders for building up self-confidence and courage to complete globally and become source of inspiration for others.</li>
            </ul>
          </section>
        </div>
        {/* <h4 className="strat-header">Goals</h4>
        <div className="goles card">
          <div className="imgGoles strat-img">
            <img
              className="tableImage"
              src={
                goles !== "N/A" || "" ? `${goles}` : `${Image_URL}/${"noPic.png"}`
              }
              alt="No File"
            />
          </div>

          <p className="strat-text">
            To develop TRM campus as a model academic institution of Madhesh Province for teaching and research.
          </p>
        </div> */}
      </div>

    </div>
  );
};

export default Strategies;
