import NepaliDate from "nepali-date/cjs/NepaliDate";
import React, { useEffect, useState } from "react";
import "./Header.css";
import "../../App.css";
import { useNavigate } from "react-router-dom";


const leftLogoStyle = {
  display: "inline-flex",
  padding: "0.4em",
  flexWrap: "nowrap",
  alignItems: "center",
};

const rightLogoStyle = {
  display: "inline-flex",
  padding: "0.4em",
  flexWrap: "nowrap",
  alignItems: "center",
  flexFlow: "row-reverse",
};



export default function Header() {


  const navigate = useNavigate()
  const handleTU = () => {
    window.open("http://www.tribhuvan-university.edu.np/", "target_blank")
  }

  const handleTRMC = () => navigate('/')
  // const date = new Date();
  const nd = new NepaliDate();
  const [currentDate, updateCurrentDate] = useState(nd);

  useEffect(() => {
    const interval = setInterval(() => {
      // updateCurrentDate(`${nd.format("mmmm d, yyyy ddd")} - ${date}`);
      updateCurrentDate(`${nd.format("mmmm d, yyyy ddd")}वार`);
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <>
      {/* <div>
       
      </div> */}
      <header className="top-header">
        <LanguageBar currentDate={currentDate} />

        <div className="logo__container">
          <div onClick={handleTU} className="logo__item left-logo" style={leftLogoStyle}>
            <Image height="80px" url={require("../Header/images/tu.png")} />
            <LogoText />
          </div>
          <Image height="50px" url={require("../Header/images/flag1.gif")} />
          {/* <Navbar /> */}
          <div onClick={handleTRMC} className="logo__item right-logo" style={rightLogoStyle}>
            <Image
              height="80px"
              url={require("../Header/images/TRMC_LOGO.png")}
            />
            <CampusLogoText />
          </div>
        </div>
      </header>
    </>
  );
}

export function Image({ url, height = "100px" }) {
  return <img src={url} className="image__logo" height={height} alt="" />;
}

export function LogoText() {
  return (
    <div className="logo__text">
      <p>
        <span className="eng_head no__wrap">{"Tribhuvan University"}</span>
      </p>
      <p>
        <span className="nep_head no__wrap">{"त्रिभुवन विश्वविद्यालय"}</span>
      </p>
    </div>
  );
}

export function CampusLogoText() {
  return (
    <div className="logo__text">
      <p>
        <span className="eng_head no__wrap">
          {"Thakur Ram Multiple Campus"}
        </span>
      </p>
      <p>
        <span className="nep_head no__wrap">
          {"ठाकुर राम बहुमुखी क्याम्पस"}
        </span>
      </p>
    </div>
  );
}

function LanguageBar({ currentDate, updateCurrentDate }) {
  return (
    <div className="header-top-bar">

      <div>
        {/* Remperoraly commented En/Np toggle */}
        {/* <input type="checkbox" className="toggle-lang" /> */}
      </div>
      <div className="nepali-dt" style={{ display: "inline-block", fontFamily: "monospace, 'sans serif'", fontSize: '12px' }}>
        <span>{`${currentDate}`}</span>
      </div>
    </div>
  );
}
