import React from "react";
import { useState, useEffect, useRef } from "react";
import "./Notice.css";
import axios from "axios";
import Spinner from "../../component/Spinner/Spinner";
import { base_URL } from "../../Middleware/config";
import { Outlet, useOutletContext } from "react-router-dom";
import SearchBox from "../../component/SearchBox/SearchBox";
import { DEPARTMENTS } from "../../utils";
import { columns } from "../../utils";
import BaseTable from "../../component/BaseTable/BaseTable";
import { motion } from "framer-motion";
import { searchNotice, NOTICE_PER_PAGE } from "../../utils";

export default function Notice() {
  const [isLoading, setIsLoading] = useState(null); // track spinner
  const [notice, setNotice] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const btnRef = useRef(null);
  // get all notices
  const getAll = async function (dID) {
    setIsLoading(true);

    await axios.get(`${base_URL}/notice/?depId=${dID}`).then((reponse) => {
      if (reponse.status === 200) {
        setNotice(reponse.data.body);
      }
      setIsLoading(false);
    });

    setIsLoading(false);
  };

  useEffect(() => {
    getAll(currentId);
  }, [currentId]);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="notice-container"
    >
      <nav className="notice__navbar">
        <ul className="notice-list">
          {DEPARTMENTS.map((dep) => {
            return (
              <li key={dep.depId}>
                <button
                  type="button"
                  ref={btnRef}
                  className="nav-btn "
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentId(dep.depId);
                  }}
                >
                  {dep.name}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>

      {notice && <Outlet context={{ notice, isLoading, currentId }} />}
    </motion.section>
  );
}

export function NoticeMain() {
  const { notice, isLoading, currentId } = useOutletContext();

  const [noticePerPage, setNoticePerPage] = useState(NOTICE_PER_PAGE);

  const [filterNotice, setFilterNotice] = useState(notice);
  const [queryText, setqueryText] = useState("");

  const totalPages = filterNotice
    ? Math.ceil(filterNotice.length / noticePerPage)
    : Math.ceil(notice.length / noticePerPage);

  const pages = [...Array(totalPages + 1).keys()].slice(1);

  const [currentPage, setCurrentPage] = useState(1);

  //first and last notice of each page
  const indexOfLastNotice = currentPage * noticePerPage;
  const indexOfFirstNotice = indexOfLastNotice - noticePerPage;

  const pageRows = filterNotice
    ? filterNotice.slice(indexOfFirstNotice, indexOfLastNotice)
    : notice.slice(indexOfFirstNotice, indexOfLastNotice);
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const nextPageHandler = () => {
    if (currentPage !== totalPages) setCurrentPage(currentPage + 1);
  };

  // updates search query
  const handleSearch = (event) => {
    setqueryText(event.target.value);
  };

  // fixes search
  useEffect(() => {
    setqueryText("");
    setCurrentPage(1);
  }, [currentId]);

  useEffect(() => {
    setFilterNotice(searchNotice(notice, queryText));
  }, [queryText, notice]);

  const content = (
    <>
      <div className="Notice-searchbox">
        <SearchBox
          placeholder={"search"}
          value={queryText}
          onChange={handleSearch}
        />
      </div>

      <BaseTable columns={columns} pageRows={pageRows} depId={currentId} />

      <div className="pagination">
        <span onClick={prevPageHandler}>Prev</span>
        {pages.map((page) => (
          <span
            className={`${currentPage === page ? "active" : ""}`}
            onClick={() => {
              setCurrentPage(page);
            }}
            key={page}
          >
            {" "}
            {page}
          </span>
        ))}
        <span onClick={nextPageHandler}>Next</span>
      </div>
    </>
  );

  return <>{isLoading === true ? <Spinner>Loading...</Spinner> : content}</>;
}
