import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdLocationCity } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { MdEmail, MdWeb } from "react-icons/md";
import { BiCurrentLocation } from "react-icons/bi";
import './Contact.css'
import { base_URL } from "../../Middleware/config";

// const address = {
//   place: "Thakur Ram Multiple Campus",
//   location: "Birgunj, Parsa Nepal",
//   phone: ["+977 9823475833"],
//   email: "trmc@testmail.com",
//   facebook: "https://facebook.com/343434",
//   website: "trmc.edu.np",
// };



const Contact = ({ className }) => {
  const [address, setAddress] = useState([]);

  const getContact = async () => {
    await axios
      .get(`${base_URL}/contact?id=1`)
      .then((result) => {
        setAddress(result.data.body);
      });
  };

  useEffect(() => {
    getContact();
    //eslint-disable-next-line
  }, []);
  return (
    <address className={className}>
      <AddressContent
        icon={<BiCurrentLocation />}
        text={
          <span>{address.title}</span>}
      />

      <AddressContent
        icon={<MdLocationCity />}
        text={
          <span>{address.address}, {address.city}, {address.state},{" "}
            {address.country}, {address.pinCode}</span>}
      />
      <AddressContent
        icon={<BsTelephone />}
        label={"Phone:"}
        text={
          <span>{address.phone}</span>}
      />
      <AddressContent
        label={"Email:"}
        icon={<MdEmail />}
        text={
          <a className="address-link" href={address.email}>
            {address.email}
          </a>
        }
      />
      <AddressContent
        icon={<MdWeb />}
        label={"Website:"}
        text={
          <>
            <a className="address-link" href="https://www.trmc.edu.np">trmc.edu.np</a>
          </>
        }
      />
    </address>
  );
};

export default Contact;

function AddressContent({ icon, label = null, text }) {
  return (
    <div className="address-item-box">
      <span className="address-icon">
        {icon}
      </span>
      &nbsp;&nbsp;
      {label && (<>
        {label}
        &nbsp;&nbsp;
      </>)}
      {text}
    </div>

  )
}