import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_URL } from "../../Middleware/config";
import "./PublicationPage.css";
import { zipFiles, getNepaliDate } from "../../utils";

const PublicationPage = () => {
  const [publicationData, setPublicationData] = useState([]);

  const getPublication = async () => {
    await axios.get(`${base_URL}/publication`).then((getData) => {
      setPublicationData(getData.data.body);
    });
  };

  const handleFileDownload = async (files) => {
    await zipFiles(files);
  };


  useEffect(() => {

  })

  useEffect(() => {
    getPublication();
  }, []);

  return (
    <>
      <header className="text-center m-2">
        <h1>Publication</h1>
      </header>
      <section className="publication-pg">
        <em className="pub-long-text">The campus has been publishing “Academic Voices Journal” since 2011 AD and its new volume has recently been published. This journal has created spirit for research atmosphere and faculty members are taking part with much curiosity. It has further provided them opportunity to concentrate on writing articles, papers to the faculty members. It has also motivated faculties to conquer at global context for research activities. In addition, Nepalese Journal of Integrated Sciences, Smart Vision, NELTA Journal and TRMC Journal are being published in the active involvement of the teachers of this campus.</em>
        <article className="publications-grid">
          {publicationData.map((pub) => {
            const filesToDownload = pub.fileName.split(",");
            const downloadNames = pub.orignalFileName.split(",");
            const files = filesToDownload.map((file, index) => {
              let x = {};
              x.name = file;
              x.downloadName = downloadNames[index];
              return x;
            });

            return (
              PublicationItem(pub, handleFileDownload, files)
            );
          })}
        </article>
      </section>
    </>
  );
};



function PublicationItem(pub, handleFileDownload, files) {
  return (<section key={pub.id} className="pub-item card">
    <PublicationTextContent header="Description" content={pub.description} />
    <PublicationTextContent header="Editors" content={pub.aurthor} />
    <PublicationTextContent header="Publisher" content={pub.department} />
    <PublicationTextContent header="About" content={pub.about} />
    <PublicationTextContent header="Publish Date" content={getNepaliDate(pub.publishDate)} />
    <h5>Attachment</h5>
    <div>
      <p className="overflow-ellipsis">
        {pub.orignalFileName.split(",")[0]}
      </p>
      {pub.orignalFileName.split(",")[0] !== "N/A" ? <button className="pub-dl-btn" onClick={() => handleFileDownload(files)}>
        Download
      </button> : ""}

    </div>
  </section>);
}


const PublicationTextContent = ({ header, content }) => {
  return (<div className="pub-item-content">
    <h6>{`${header}:`}&nbsp;&nbsp;</h6>
    <p>{content}</p>

  </div>)
}
export default PublicationPage;