import React from "react";
import "./CoursePage.css";

export default function CoursePage() {
  return (
    <section>
      <header className="course-title">
        <h1>Courses @TRMC</h1>
      </header>
      <div className="course-container">
        <div id="test" className="one">
            <img className="main-img" src="https://wallpapercave.com/wp/wp4563489.jpg" alt="" width="100%"/>
            <p className="vertical-bar">Courses</p>
        </div>

       <div className="sub-container">
        <div className="two" id="test">
            <img className="main-img" src="https://wallpapercave.com/wp/wp4563489.jpg" alt="" width="100%"/>
            <p>Enginnering Courses</p>
        </div>
        <div className="three" id="test">
            <img className="main-img" src="https://wallpapercave.com/wp/wp4563489.jpg" alt="" width="100%"/>
            <p>Pharmacy</p>
        </div>
        <div className="four" id="test">
            <img className="main-img" src="https://wallpapercave.com/wp/wp4563489.jpg" alt="" width="100%"/>
            <p>Management Cousres</p>
        </div>
        <div className="five" id="test">
            <img className="main-img" src="https://wallpapercave.com/wp/wp4563489.jpg" alt="" width="100%"/>
            <p>Polytechnic</p>
        </div>
        <div className="five" id="test">
            <img className="main-img" src="https://wallpapercave.com/wp/wp4563489.jpg" alt="" width="100%"/>
            <p>Agriculture</p>
        </div>
        <div className="five" id="test">
            <img className="main-img" src="https://wallpapercave.com/wp/wp4563489.jpg" alt="" width="100%"/>
            <p>Apply Now</p>
        </div>
       </div>
    </div>
    </section>
  );
}

function CourseCard(props) {
  return (
    <div
      classNameName="course-card"
      style={{ width: props.width, height: props.height }}
    >
      <div classNameName="course-card-image">
        <img src="https://i.pravatar.cc/150?img=1" alt="" />
      </div>
      <p classNameName="course-title">TEXT AREA</p>
    </div>
  );
}
