import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_URL } from "../../Middleware/config";
import { ProfileCardItem } from '../../Middleware/helper';
import "./StaffPage.css";
import Spinner from "../../component/Spinner/Spinner";

const KeyStaff = () => {
  const [isLoading, setIsLoading] = useState(null); // track spinner
  const [authoritiesData, setAuthorities] = useState([]);

  const getAuthorityMember = async () => {
    setIsLoading(true);
    var authoritiesIds = await axios.get(`${base_URL}/staffsStricture/`).then((getData) => {
      return getData.data.body[0].campusAuthoritiesIds.split(',');
    });

    await axios.post(`${base_URL}/staffs/getStricture`, authoritiesIds).then((getData) => {
      setAuthorities(getData.data.body);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getAuthorityMember();
  }, []);

  return (
    <>
      <header className="text-center mb-1 mgt-com-header">
        <h1>Campus Authorities</h1>
      </header>
      <section className="mgt-com-pg">
      <p>The following top officials look after both everyday academic and non-academic affairs of the campus. They are also responsible for overall supervision, monitoring and evaluation of faculty members, administrative staff and students.</p>
        <article className="mgt-com-container">
          {authoritiesData.map((comData) => {
             return <ProfileCardItem data={comData} />;
          })}
        </article>
      </section>
      <>{isLoading === true ? <Spinner>Loading...</Spinner> : ''}</>
    </>
  );
};
export default KeyStaff;


// export default function KeyStaff() {
//   return (
//     <div className="campus-authorities">
//       {/* <div className="staff-heading">Key Officials</div> */}
//       <h2 className="staff-heading m-1">Campus Authorities</h2>
//       <p className="mt-1">The following top officials look after both everyday academic and non-academic affairs of the campus. They are also responsible for overall supervision, monitoring and evaluation of faculty members, administrative staff and students.</p>
//       <div className="staff-container">      
//         <KeyStaffDetail />
//       </div>
//     </div>
//   );
// }

// function KeyStaffDetail(props) {
//   const [keyStaff, setKeyStaff] = useState([]);

//   const Id = [71,78,112,131,147,156,73,135,105];

//   // const KeyStaffDetail = async (id,keyId) => {
//   //   await axios
//   //     .get(`${base_URL}/staffs/searchEmpByType?id=${id}&keyId=${keyId}`)
//   //     .then((getData) => {
//   //       setKeyStaff(getData.data.body); // multiple data received loop required
//   //   });
//   // };

//   const KeyStaffDetail = async () => {
//     await axios
//       .post(`${base_URL}/staffs/getStricture`, Id)
//       .then((getData) => {
//         setKeyStaff(getData.data.body); // multiple data received loop required
//     });
//   };

//   useEffect(() => {
//     KeyStaffDetail();
//   }, []);



  
//   return (
//     <>
//       {keyStaff.map((ts) => {
//         const avatar =
//           ts.profilePic !== "N/A"
//             ? `${base_URL}/images/${ts.profilePic}`
//             : "https://avatars.mds.yandex.net/i?id=a4137a3813859a900a99b4e1c9b80e4d9af05003-8220238-images-thumbs&n=13";
//         return (
//           <div key={ts.id} className="staff-detail-card">
//             <div className="avatar">
//               <img
//                 style={{ display: "inline-block", margin: "10px" }}
//                 src={avatar}
//                 className="staff-picture"
//                 alt=""
//               />
//             </div>
//             <div className="staff-information">
//               <Text content={`Name: ${ts.fullName}`} />
//               <Text content={ts.committeeId === 1 ? `Position: ${ts.designation} (${ts.committee})` : `Position: ${ts.designation}`} />
//               <Text content={`Faculty: ${ts.department}`} />
//             </div>
//           </div>
//         );
//       })}
//     </>
//   );
// }

// function Text({ content }) {
//   return <p className="text">{content}</p>;
// }
