import React from "react";
import "./Spinner.css";
export default function Spinner(props) {
  return (
    <div className="spinner-container">
      <div className="spinner-text" style={{color: 'red', fontWeight:"bold", fontSize: '2rem'}}>{props.children}</div>
      <div className="loading-spinner"></div>
    </div>
  );
}
