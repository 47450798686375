import React from "react";
import Contact from "../../component/Contact/Contact";
import Map from "../../component/Map/Map";
import campus from "../../../src/images/campus.png";
import "./ContactPage.css";

export default function ContactPage() {
  return (
    <section className="contacts-pg">
      <h1 className="main-heading m-1 mb-2">Contact Us</h1>
      <article className="content-address">
        <div className="contact-img">
          <img src={campus} alt="campus" />
        </div>
        <div className="contact-card">
          <Contact className="address" />
        </div>
      </article>
      <section className="section-map">
        <Map />
      </section>
    </section>
  );
}
