import React from "react";

export default function Map() {
  return (
    <div className="gmap_canvas">
      <iframe
        title="Thakur Ram Campus"
        className="gmap_iframe"
        style={{
          border: "none",
          // boxShadow: "0px 1px 10px 8px rgba(0,0,0, 0.2)",
        }}
        width="100%"
        height={300}
        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Thakur Ram Campus&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
    </div>
  );
}
