import React from 'react'
import '../lifeCommon.css'
import './BoysHostel.css';
// import noImageFound from "../../../images/no-image-found.png";
import img1 from "../../../images/BoysHostel/Hostel1.jpg";

const BoysHostel = () => {
  return (
    <>
    <header className="main-heading m-2">
      <h1>Boys Hostel</h1>
    </header>
    <section className='trmc-page'>
      <div className="trmc-content">
        <img className="trmc-img" src={img1} alt="Image" />
        <div className="trmc-text">
          <p>The campus has separate Boys hostel. The hostel facility has been provided to the limited number of students out of the total strength of 6852 students. Due to limited resources, the hostel facility has not so far been extended for many years and the campus is planning towards the extension of required hostel facilities for the students of rural areas through internal and external sources.</p>
        </div>
      </div>
    </section>
  </>
  )
}

export default BoysHostel