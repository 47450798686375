import React from 'react'
import './FlashNoticeModal.css'

const FlashNoticeModal = ({ imageSrc, altText, message, className, onClose }) => {
    const handleContextMenu = (event) => {
        event.preventDefault();
      };
    return (
        <div className={`modal-overlay ${className}`}>
            <div className="modal">
                <button className="close-btn" onClick={onClose}>X</button>
                <img src={imageSrc} alt={altText} onContextMenu={handleContextMenu}/>
                <p>{message}</p>
            </div>
        </div>
    );
};


export default FlashNoticeModal;
