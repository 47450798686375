import React from "react";
import "./App.css";

import FooterPage from "./pages/Footer/FooterPage";
import { NavMenu } from "./component/Navbar/Navbar";
import AnimatedRoutes from "./component/AnimatedRoutes/AnimatedRoutes";
import Header from "./component/Header/Header";
import FlashNoticeModal from "./component/FlashNoticeModal/FlashNoticeModal";
import axios from "axios";
import { useState, useEffect } from "react";
import { base_URL } from "./Middleware/config";
import { Image_URL } from "./Middleware/config";

function App() {

  const [flashNotice, setFlashNotice] = useState([]);
  const [showFlashNotice, setShowFlashNotice] = useState(true);
  const [currentFlashNotice, setCurrentFlashNotice] = useState(0)

  const handleButtonClick = () => {
    // hide flash notice once we have shown all of the flash notices
    if (currentFlashNotice === flashNotice.length - 1)
      setShowFlashNotice(false)

    // update flash notice component when current flash index changes.
    setCurrentFlashNotice(prevState => {
      return currentFlashNotice !== flashNotice.length ? (prevState + 1) % flashNotice.length : 0
    })
  };

  const searchflashNotice = () => {
    axios.get(`${base_URL}/notice/flashNotice`).then(result => {
      const flashNotice = result.data.body
      setFlashNotice(flashNotice);
    });
  }

  useEffect(() => {
    searchflashNotice();
    // setShowFlashNotice(true);
  }, [])

  return (
    <>
      {
        flashNotice && flashNotice.length &&
        <FlashNoticeModal
          imageSrc={!flashNotice[currentFlashNotice].fileName ? "https://avatars.mds.yandex.net/i?id=89cd182790fb4d452e2416da6b866f6682ae5c70-8271622-images-thumbs&n=13" : `${Image_URL}/${flashNotice[currentFlashNotice].fileName}`}
          altText={flashNotice[currentFlashNotice].fileName}
          message={flashNotice[currentFlashNotice].description}
          className={showFlashNotice ? 'active' : ''}
          onClose={handleButtonClick}
        />
      }
      <Header />
      <NavMenu />
      <section className="main-content-section">
        <main className="main-content-body" >
          <AnimatedRoutes />
        </main>
        <FooterPage />
      </section>
    </>
  );
}

export default App;
