import "./Slider.css";
import React from "react";

import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { useRef, useState } from "react";
//import crousal0 from "../../../src/images/campus.jpg";
import crousal1 from "../../images/crousal1/c1.jpg";
import crousal2 from "../../images/crousal1/c2.jpeg";
//import crousal3 from "../../images/crousal1/c3.jpeg";
//import crousal4 from "../../images/crousal1/c4.jpg";
import crousal5 from "../../images/crousal1/c5.jpeg";
//import crousal6 from "../../images/crousal1/c6.jpg";
//import crousal7 from "../../images/crousal1/c7.jpg";
import { useEffect } from "react";
export default function Slider(props) {

  const imagesArr = [crousal1, crousal2, crousal5];
  const imgRef = useRef(null);
  const [currImg, setCurrImage] = useState(0);

  const nextHandler = (e) => {
    currImg === imagesArr.length - 1 ? setCurrImage(0) : setCurrImage(currImg + 1);
  };


  const prevHandler = (e) => {
    currImg !== 0 ? setCurrImage(currImg - 1) : setCurrImage(imagesArr.length - 1)
  };

  useEffect(() => {
    const dots = Array.from(document.querySelector('.dot-container').children);
    dots.forEach((elem, index) => {
      elem.addEventListener('click', () => {
        setCurrImage(index);
      })
    })

  }, [])

  useEffect(() => {
    const x = (document.querySelector('.dot-container').children)
    const dots = Array.from(x);
    dots.forEach((e, i) => {
      i === currImg ? e.classList.add('activated') : e.classList.remove('activated');
    })

  }, [currImg])

  useEffect(() => {
    const lastImage = currImg === imagesArr.length - 1;

    const interval = setInterval(() => {
      lastImage ? setCurrImage(0) : setCurrImage(prev => prev + 1)
    }, 4000)
    return () => clearInterval(interval);
  })

  return (
    <>
      <section className="SlideContainer">
        <div className="slide-img-hm">
          <div
            ref={imgRef}
            className="SlideImageContent"
            // imgSrc={imagesArr[currImg]}
            style={{ backgroundImage: `url("${imagesArr[currImg]}")` }}>
          </div>

        </div>

        <div className="SliderButtonBox">
          <FiChevronLeft className="PrevButton" onClick={prevHandler} />

          <FiChevronRight className="NextButton" onClick={nextHandler} />
        </div>
        <TextBanner
          text={"An investment in knowledge pays the best interest."}
        />
        <div className="dot-container">
          {imagesArr.map((elem, index) => {
            return currImg === index ? <span className="dot activated"></span> :
              <span className="dot"></span>
          })}
        </div>
      </section>
    </>
  );
}

function TextBanner(props) {
  return <p className="banner">{props.text}</p>;
}
