import React, { useEffect, useRef, useState } from 'react';
import './QAA.css'
import axios from 'axios'
import { base_URL } from '../../Middleware/config';
// import { Link } from 'react-router-dom'


// import { UnderConstructionPage } from '../../Middleware/helper';
// import '../../pages/Association/EmployeesAssociation/EmployeeAssociation.css';

const QAA = () => {

  //const auth = localStorage.getItem("key")

  const [formData, setFormData] = useState({
    userName: "",
    qaaPassword: '',
    //token: JSON.parse(auth).token
  })

  const [qaaDocc, setQaaDocc] = useState();
  const [hasVerified, setVerified] = useState(false)
  const [message, setMessage] = useState("")

  const getQaaDocc = async () => {
    const response = await axios.get(`${base_URL}/qaa/getQaaDocc`)
    const data = await response.data
    if (response.status !== 200) {
      console.log("data", "no data"
      )
    } else {
      console.log("data", data);
      setQaaDocc(data.body)
    }
  }


  useEffect(() => {
    if (hasVerified) {
      // hide modal
      modalRef.current.style.display = "none"
      document.querySelector(".ssr-btn").style.display = "none"
      getQaaDocc()
    }
  }, [hasVerified])

  const modalRef = useRef(null)
  return (
    <>
      <header className="text-center m-2">
        <h1>QUALITY ASSURENCE AND ACCREDITATION PROGRAM</h1>
      </header>

      <section className='qaa-container'>
        <div ref={modalRef} className='qaa-password-modal'>

          <div className="modal-content">
            <div className='close' onClick={() => { modalRef.current.style.display = "none" }}>x</div>
            <label htmlFor="userName">Username&nbsp;&nbsp;
              <input type="text" name="userName" onChange={(e) => {
                setFormData({ ...formData, userName: e.target.value })
              }} id="" />
            </label>
            <label htmlFor="qaaPassword">Password&nbsp;&nbsp;&nbsp;
              <input type="password" name="qaaPassword" onChange={(e) => {
                setFormData({ ...formData, qaaPassword: e.target.value })
                console.log(formData)
              }} id="" />
            </label>
            <dir style={{ color: "red" }}>{message}</dir>
            <div>
              <button className='button-62' style={{ margin: "4px 16px", padding: "4px 16px" }} onClick={
                async () => {
                  console.log("formdata", formData)
                  const response = await axios.post(`${base_URL}/login/qaaLogin`, formData);
                  const x = await response.data
                  // console.log(x)
                  if (response.status === 200) {
                    x.message === "You are successfully signed In." ? setVerified(true) : setVerified(false)
                    if (x.message !== "You are successfully signed In.") {
                      setMessage(x.message)
                    }
                  } else {
                    setVerified(false)
                  }
                }
              }>Verify</button>
            </div>

          </div>
        </div>
        <article>
          <header style={{ margin: "1rem" }}>
            <button className='button-41 ssr-btn' onClick={() => modalRef.current.style.display = "flex"}>SSR</button>
          </header>
          {qaaDocc && hasVerified &&
            <div className='ssr_table'>
              <table >
                <thead>
                  <tr>
                    <th style={{ width: "100px" }}>S.No</th>
                    <th style={{ color: "#333" }}>Files</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    qaaDocc.map((data, index) => {
                      return (<>
                        {/* <td className='fileContent'>{data.doccName}</td> */}
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ textAlign: "center" }}><a target='_blank' rel={'external'} href={`${data.doccLink}`} onClick={() => {
                          }} className='btn'>{data.doccName}</a></td>
                        </tr>
                      </>)
                    })
                  }

                </tbody>
              </table>
            </div>
          }
        </article>
      </section>
    </>
  );
}

export default QAA