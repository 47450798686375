import React from 'react'
import '../lifeCommon.css'
import './GirlsHostel.css';
// import noImageFound from "../../../images/no-image-found.png";
import img1 from "../../../images/GirlsHostel/image1.jpg";

const GirlsHostel = () => {
  return (
    <>
    <header className="main-heading m-2">
      <h1>Girls Hostel</h1>
    </header>
    <section>
    <section className='trmc-page'>
      <div className="trmc-content">
        <img className="trmc-img" src={img1} alt="Image" />
        <div className="trmc-text">
          <p>The campus has separate Girls (50 rooms) hostel. In total 100 girls are availing the hostel facilities. The hostel facility has been provided to the limited number of students out of the total strength of 6852 students. Due to limited resources, the hostel facility has not so far been extended for many years and the campus is planning towards the extension of required hostel facilities for the students of rural areas through internal and external sources.</p>
        </div>
      </div>
    </section>
    </section>
  </>
  )
}

export default GirlsHostel