import "./HomeNoticeComponent.css";
import { formatNoticeDate } from "../../utils";
import { useNavigate } from "react-router-dom";

const HomeNoticeComponent = (props) => {
  const changeLocation = useNavigate();

  if (props.notice.length === 0) {
    return <h2>No Notice</h2>;
  }
  return (
    <div
      className="hm-notice-content"
      onClick={() => {
        changeLocation("/notice");
      }}
    >
      {props.notice.map((n, index) => {
        let parseDate = formatNoticeDate(n.createdDate);
        return (
          <div key={index} className="hm-notice-item card">
            <div className="date-container">
              <p className="date-content">{parseDate.date}</p>
              <p className="month-content">{parseDate.month}</p>
            </div>
            <div className="hm-notice-text line-clamp">
              <p>{n.description}</p>

            </div>
          </div>
        );
      })}
    </div>
  );
};
export default HomeNoticeComponent;
