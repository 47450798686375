import React from "react";
import "./CampusMessage.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { base_URL } from "../../../../Middleware/config";
import { Image_URL } from "../../../../Middleware/config";

export default function CampusMessage() {
  const [committeeData, setCommitteeData] = useState([]);
  const changeLocation = useNavigate();
  const chiefId = [1];

  const getCommitteeMember = async () => {
    await axios
      .post(`${base_URL}/staffs/searchCommittee`, chiefId)
      .then((getData) => {
        setCommitteeData(getData.data.body[0][0]);
      });
  };
  
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getCommitteeMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="campus-message">
      <div className="campus-image-content">
        <img
          width={100}
          height={100}
          src={
            committeeData.profilePic !== "N/A" || ""
              ? `${Image_URL}/${committeeData.profilePic}`
              : `${Image_URL}/${"noPic.png"}`
          }
          alt="dean"
          onContextMenu={handleContextMenu}
        />
      </div>
      <div className="text-content">
        <p>{committeeData.about}</p>
        <br />
        <button
          className="btn button-sm"
          onClick={() => {
            changeLocation("/chiefMessage");
          }}
        >
          <span>Read More...</span>
        </button>
      </div>
    </div>
  );
}
