import axios from "axios";
import React, { useState, useEffect } from "react";
import { base_URL, Image_URL } from "../../../Middleware/config";
import "./TeachersAssociation.css";
import { handleImageClick } from "../../../Middleware/helper";

const TeachersAssociation = () => {
  // const aboutAssociation =
  //   "TRIBHUVAN UNIVERSITY TEACHERS’ ASSOCIATION (TUTA), a non-profitable organization which is established with the objectives of teacher welfare and overall academic enhancement of Tribhuvan University. A legal institution constituting in every campus as a unit with its own legal provision, Thakur Ram Multiple Campus has also formed TUTA unit under the policy of TU. Established in 2038 B.S. It has been working for the welfare of the teachers and campus as well. This unit conducts Periodic Seminars, interaction programs, financial supports for the teachers suffering from chronic diseases, and many more. The unit also played a remarkable role in different Democratic movements of Nepal. Further, this Unit pursues the helping hands to enhance the academic activities; like publishing research journals and to help the administration activities of the campus. It provides direct and indirect cooperation to make the campus decentralized from the university being an autonomous Campus. Moreover, the formation of the unit takes place every three years through the proportional election method among the teachers.";

  const [associationData, setAssociationData] = useState([]);

  const associationId = [9, 14, 15, 16, 17, 22 ,25];

  const getAssociationMember = async () => {
    await axios
      .post(`${base_URL}/staffs/searchCommittee`, associationId)
      .then((getData) => {
        setAssociationData(getData.data.body);
      });
  };


  useEffect(() => {
    getAssociationMember();
  });

  return (
    <div className="teacher-pg">
      <h2 className="text-center mt-1 mb-1">
        Tribhuvan University Teachers Association
      </h2>
      {/* <p style={{ fontFamily: "sans-serif" }}>{aboutAssociation}</p> */}
      <p className="mt-1">TRIBHUVAN UNIVERSITY TEACHERS’ ASSOCIATION (TUTA), a non-profitable organization which is established with the objectives of teacher welfare and overall academic enhancement of Tribhuvan University. A legal institution constituting in every campus as a unit with its own legal provision, Thakur Ram Multiple Campus has also formed TUTA unit under the policy of TU. Established in 2038 B.S. It has been working for the welfare of the teachers and campus as well. This unit conducts Periodic Seminars, interaction programs, financial supports for the teachers suffering from chronic diseases, and many more. The unit also played a remarkable role in different Democratic movements of Nepal. Further, this Unit pursues the helping hands to enhance the academic activities; like publishing research journals and to help the administration activities of the campus. It provides direct and indirect cooperation to make the campus decentralized from the university being an autonomous Campus. Moreover, the formation of the unit takes place every three years through the proportional election method among the teachers.</p>
      <h2 className="text-center mt-1 mb-2 ">
        TUTA Thakur Ram Multiple Campus{" "}
      </h2>
      <section className="teacher-assoc">
        {associationData.map((comData) => {
          return <AssociationItem data={comData} />;
        })}
      </section>
    </div>
  );
};

function AssociationItem(props) {
  return props.data.map((com) => {
    return (
      <div key={com.id} className="teacher-item card">
        {com.profilePic === "N/A" ? (
          <div className="text-center profilepic">
            <img
              src={`${Image_URL}/${"noPic.png"}`}
              height={100}
              width={100}
              alt=""
            />
          </div>
        ) : (
          <div className="text-center profilepic">
            <img
              src={`${Image_URL}/${com.profilePic}`}
              height={100}
              width={100}
              alt=""
              onContextMenu={handleImageClick}
            />
          </div>
        )}
        <p>
          <span className="label">Name</span>: {com.fullName}
        </p>
        <p>
          <span className="label">Department</span>: {com.department}
        </p>
        <p>
          <span className="label">Designation</span>: {com.designation}
        </p>
        <p>
          <span className="label">Association</span>: {com.committee}
        </p>
      </div>
    );
  });
}

export default TeachersAssociation;
