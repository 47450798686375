import "./Gallery.css";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { base_URL } from "../../Middleware/config";
import { motion } from "framer-motion";
import { getNepaliDate } from "../../utils";

export function Gallery() {
  const [displayImage, setDisplayImage] = useState([]);

  const getImages = async () => {
    await axios.get(`${base_URL}/gallery/all`).then((result) => {
      let data = result.data.body;
      let gallery = [];

      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let galleryImage = {};
          galleryImage.id = data[i].id;
          galleryImage.title = data[i].title;
          galleryImage.date = data[i].date;
          galleryImage.description = data[i].description;
          galleryImage.images = data[i].images.toString().split(",");
          galleryImage.thumbnails = data[i].thumbnails.toString().split(",");
          gallery.push(galleryImage);
        }
        setDisplayImage(gallery);
      }
    });
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="gallery-pg"
    >
      <h2 className="text-center mb-3 mt-1">Gallery</h2>
      <div className="gallery-grid">
        {displayImage.map((item) => {
          return (
            <article className="gallery-item">
              <header className="mb-1 text-center">
                <h5>{item.title}</h5>
                <p className="gallery-desc">{item.description}</p>
                <p className="gallery-date">{getNepaliDate(item.date)}</p>
              </header>
              <section className="gallery-image-container">
                <GalleryImg src={item.images} thumb={item.thumbnails} />
              </section>
            </article>
          );
        })}
      </div>
    </motion.section>
  );

  function GalleryImg(props) {
    useEffect(() => {});
    const [imageOpenUrl, setImageOpenUrl] = useState("");
    const imageDialogRef = useRef(null);

    function handleOpenImage(imgUrl) {
      imageDialogRef.current.showModal();
      setImageOpenUrl(imgUrl);
    }
    return (
      <>
        {props.src.map((image, index) => (
          <>
            <div className="img-wrapper">
              <div
                style={{
                  backgroundImage: `url("${base_URL}/images/${props.thumb[index]}")`,
                }}
                className="image-holder zoom"
                onClick={() => {
                  handleOpenImage(`url("${base_URL}/images/${image}")`);
                }}
              >
                <div className="overlay"></div>
              </div>
            </div>

            <dialog
              ref={imageDialogRef}
              style={{
                backgroundImage: `${imageOpenUrl}`,
              }}
              className="galleryDialog"
            >
              <span onClick={() => imageDialogRef.current.close()}>x</span>
            </dialog>
          </>
        ))}
      </>
    );
  }
}
