import axios from "axios";
import React, { useState, useEffect } from "react";
import "../../../src/logo192.png";
import "../AboutPage/AboutPage.css";
import campus_img from "../../../src/images/campus_text.jpg";
// import { motion, transform } from "framer-motion";
import ReactHTMLParser from "html-react-parser";
import DOMPurify from "dompurify";
import graduate from '../../../src/images/graduate.png'
import professor from '../../../src/images/professor.png'
import Administrative_Staff from '../../../src/images/Administrative_Staff.png'
import classroom from '../../../src/images/classroom.png'
import { useRef } from "react";
import { base_URL } from "../../Middleware/config";
// import { animate } from "framer-motion";


export default function AboutPage() {
  const objStudent = {
    url: graduate,
    alt: "demo",
    num: 9999,
    text: "Students",
  };

  const objTeachers = {
    url: professor,
    alt: "demo",
    num: 130,
    text: "Teachers",
  };

  const objAwards = {
    url: Administrative_Staff,
    alt: "demo",
    num: 70,
    text: "Administrative Staff",
  };

  const objClassroom = {
    url: classroom,
    alt: "demo",
    num: 40,
    text: "Classrooms",
  };

  const [animate, setAnimate] = useState(false)


  const [contactData, setContactData] = useState([]);

  const getContact = async () => {
    await axios.get(`${base_URL}/contact`).then((result) => {
      setContactData(result.data.body[0]);
    });
  };

  useEffect(() => {
    getContact();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const item = document.querySelector('.about-item')
    const observer = new IntersectionObserver((entries) => {
      setAnimate(entries[0].isIntersecting)
      item.classList.toggle('show', entries[0].isIntersecting)
    })

    observer.observe(item)

    return () => observer.unobserve(item);
  }, [])


  return (
    <section className="about-pg">
      <header className="main-heading m-2">
        <h3>About Us</h3>
      </header>

      <div className="about-content">
        <img className="about-img" src={campus_img} alt="campus" />
        <div className="about-text">
          {ReactHTMLParser(
            DOMPurify.sanitize(contactData.campusIntroduction, {
              USE_PROFILES: { html: true },
            })
          )}
        </div>
      </div>
      <br />
      <div
        className="about-item ">
        <ImageWithText obj={objStudent} animate={animate} />
        <ImageWithText obj={objTeachers} animate={animate} />
        <ImageWithText obj={objAwards} animate={animate} />
        <ImageWithText obj={objClassroom} animate={animate} />
      </div>
      <br />
    </section>
  );
}

function ImageWithText(props) {

  const [number, setNumber] = useState(0);
  const numRef = useRef(number);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber(prev => {
        const newResult = prev + 1
        numRef.current = newResult
        return newResult;
      })

      if (numRef.current === props.obj.num || numRef.current >= 400)  {
        setNumber(props.obj.num)
        clearInterval(interval);
      }
    }, 5)

    setNumber(0);
    return () => clearInterval(interval);
  }, [props.animate])

  return (
    <div className="container">
      <div className="img-box">
        <img
          src={props.obj.url}
          alt={props.obj.alt}
          width="60px"
          height="60px"
        />
        <div className="text-box">
          <p style={{ fontWeight: "bold" }}>{number}+</p>
          <p>{props.obj.text}</p>
        </div>
      </div>
    </div>

  );
}
