/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { AiFillCaretDown } from "react-icons/ai";

export function NavMenu() {
  const navRef = useRef(null);

  const toggleNav = () => {
    if (navRef.current.style.display !== "flex") {
      navRef.current.style.display = "flex";
    } else {
      navRef.current.style.display = "none";
    }
  };

  const [isMobileView, setMobileView] = useState(false)

  const closeNav = () => {
    navRef.current.style.display = "none"
  }

  useEffect(() => {
    function windowResizeListener() {
      if (window.innerWidth > 768) {
        navRef.current.style.display = "flex";
      } else {
        navRef.current.style.display = "none";
      }
    }

    window.addEventListener("resize", windowResizeListener);

    return () => {
      window.removeEventListener("resize", windowResizeListener);
    };
  });

  useEffect(() => {
    const elements = document.querySelectorAll('.menu-item')
    elements.forEach(e => e.addEventListener('click', () => {
      // toggleNav()
      if (window.innerWidth <= 768) {
        setMobileView(true)
        navRef.current.addEventListener('ontouchstart', () => { })
      } else {
        setMobileView(false)
      }
    }));
  }, [])

  return (
    <div style={{ zIndex: 1 }} className="sticky">
      <nav className="menu-bar card">
        <ul ref={navRef}>
          <li className="menu-item">
            <div><NavLink to="/" onClick={() => isMobileView && closeNav()}>Home</NavLink></div>
          </li>
          <li className="menu-item">
            <div>
              <a href="#">About</a>
              <AiFillCaretDown />
            </div>
            <div className="sub-menu-1">
              <ul>
                <li className="lvl-one-item">
                  <NavLink onClick={() => isMobileView && closeNav()} to="/about">About Us</NavLink>
                </li>
                <li className="lvl-one-item">
                  <NavLink to="/chiefMessage" onClick={() => isMobileView && closeNav()}>
                    Message from Campus Chief
                  </NavLink>
                </li>
                <li className="lvl-one-item" onClick={() => isMobileView && closeNav()}>
                  <NavLink to="/Strategies">Vision, Mission and Goal</NavLink>
                </li>
                <li className="lvl-one-item" onClick={() => isMobileView && closeNav()}>
                  <NavLink to="/mgmtCommittee">
                    Campus Management Committee
                  </NavLink>
                </li>
                <li className="lvl-one-item" onClick={() => isMobileView && closeNav()}>
                  <NavLink to="/executiveCommittee">
                    Executive Committee
                  </NavLink>
                </li>
                <li className="lvl-one-item" onClick={() => isMobileView && closeNav()}>
                  {" "}
                  <NavLink to="/chart">Orginazation Chart</NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li onClick={() => isMobileView && closeNav()} className="menu-item">
            <div><NavLink to="/notice">Notice</NavLink></div>
          </li>
          <li className="menu-item">
            <div><a href="#">Staffs</a>
              <AiFillCaretDown />
            </div>
            <div className="sub-menu-1">
              <ul>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/keyStaff">Campus Authorities</NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/staff">Teaching Staffs</NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/nonTeachingsstaff">Non Teaching Staffs</NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li onClick={() => isMobileView && closeNav()} className="menu-item">
            <div>
              <NavLink to="/programs">Programmes</NavLink>
            </div>
          </li>
          <li onClick={() => isMobileView && closeNav()} className="menu-item">
            <div><NavLink to="/gallery">Gallery</NavLink></div>
          </li>
          <li className="menu-item">
            <div>
              <a href="#">Associations</a>
              <AiFillCaretDown />
            </div>
            <div className="sub-menu-1">
              <ul>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/teachersAssociation">
                    TU Teachers Association
                  </NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/employeeAssociation">
                    TU Employees Association
                  </NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/studentUnion">Free Students Union</NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li className="menu-item">
            <div>
              <a href="#">Life@TRMC</a>
              <AiFillCaretDown />
            </div>
            <div className="sub-menu-1">
              <ul>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/stuWelfareUnit">Student Welfare Unit</NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/cafetria">TRMC Cafetria</NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/sports">Sports & Health</NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/gHostel">TRMC Girls Hostel</NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/bHostel">TRMC Boys Hostel</NavLink>
                </li>
                <li onClick={() => isMobileView && closeNav()} className="lvl-one-item">
                  <NavLink to="/library ">Library</NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li onClick={() => isMobileView && closeNav()} className="menu-item">
            <div>
              <NavLink to="/publication">Publication</NavLink>
            </div>
          </li>
          <li onClick={() => isMobileView && closeNav()} className="menu-item">
            <div><NavLink to="/QAA">QAA</NavLink></div>
          </li>
          <li onClick={() => isMobileView && closeNav()} className="menu-item">
            <div> <NavLink to="/contact">Contact Us</NavLink></div>
          </li>
        </ul>
        <div
          onClick={() => {
            toggleNav();
          }}
          className="overflow-menu hidden"
        >
          <MdMenu />
        </div>
      </nav>
    </div>
  );
}

