import React from 'react'
import './SportsAndHealth.css';
import '../lifeCommon.css';
import img1 from "../../../images/SportsAndHealth/SportsAndHealth.jpg";
const SportsAndHealth = () => {
  return (
    <>
      <header className="main-heading m-2">
        <h1>Sports And Health</h1>
      </header>
      <section>
        <section className='trmc-page'>
          <div className="trmc-content">
            <img className="trmc-img" src={img1} alt="Image" />
            <div className="trmc-text">
              <p>Sports create the physical as well as mental quality in understudies. Every day physical work out is basic for understudies since work out not as it were making a difference understudies to remain solid, but it too makes a difference to progress their passionate fitness. Sports ought to be a major portion of the campus educational programs since in the event that understudies remain sincerely and physically sound, they can effortlessly center on them ponders. Understudies have to be taken part in campus sports to extend certainty, mental sharpness, and self-esteem. Sports are imperative in campus since it makes a difference to educate different abilities to understudies like authority, tolerance, persistence, group endeavors, and social aptitudes.</p>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default SportsAndHealth