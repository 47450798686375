import React from 'react'
import '../lifeCommon.css'
import './StudentWelfare.css';
import img1 from "../../../images/student_union.jpg"; //student_union.jpg

const StudentWelfare = () => {
  return (
    <>
    <header className="main-heading m-2">
      <h1>Student Welfare</h1>
    </header>    
    <section className='trmc-page'>
      <div className="trmc-content">
        <img className="trmc-img" src={img1} alt="img" />
        <div className="trmc-text">
          <p>Students’ welfare section of this campus organized programs such as blood donation, plantation, sanitation, raised relief fund for disasters victims. This section organized Thakur Ram Premier League (TPL) and also initiated a training center for Cricket in this year. The campus is providing free-ship to poor and meritorious students at Bachelor level. Besides, all the disabled students studying at both Bachelor and Master levels are enjoying free studentship.</p>
        </div>
      </div>
    </section>
  </>
  )
}

export default StudentWelfare