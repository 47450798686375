import axios from "axios";
import React, { useState, useEffect } from "react";
import { base_URL, Image_URL } from "../../../../Middleware/config";
import "./CampusChief.css";

const CampusChief = () => {
  const [committeeData, setCommitteeData] = useState([]);

  const chiefId = [1];

  const getCommitteeMember = async () => {
    await axios
      .post(`${base_URL}/staffs/searchCommittee`, chiefId)
      .then((getData) => {
        setCommitteeData(getData.data.body[0][0]);
      });
  };
  
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getCommitteeMember();
    //eslint-disable-next-line
  }, []);
  return (
    <>
    <div className='chairman-page'>
      <div className="chairman-wrapper">
      <h2>Message From Chairman's Desk</h2>
        <img
          className="chairman-img"
          src={
            committeeData.profilePic !== "N/A" || ""
              ? `${Image_URL}/${committeeData.profilePic}`
              : `${Image_URL}/${"noPic.png"}`
          }
          alt="No File"
          onContextMenu={handleContextMenu}
        />
        
        <p>{committeeData.about}</p>
        <h4>{committeeData.fullName}</h4>
        <h5>{committeeData.committee}</h5>
      </div>
      <div className="clear"></div>
    </div>
    </>
  );
};

export default CampusChief;
