import React from "react";
import { Image_URL } from "../Middleware/config";
import "./helper.css";

function ProfileCardItem(props) {
  return props.data.map((com) => {
    return (
      <div className="committee-item card">
        {com.profilePic === "N/A" ? (
          <div className="text-center profilepic">
            <img
              src={`${Image_URL}/${"noPic.png"}`}
              height={100}
              width={100}
              alt=""
            />
          </div>
        ) : (
          <div className="text-center profilepic">
            <img
              src={`${Image_URL}/${com.profilePic}`}
              height={100}
              width={100}
              alt=""
              onContextMenu={handleImageClick}
            />
          </div>
        )}
        <p>
          <span className="label">Name</span>: {com.fullName}
        </p>
        <p>
          <span className="label">Department</span>: {com.department}
        </p>
        <p>
          <span className="label">Designation</span>: {com.designation}
        </p>
        <p>
        {com.committee && (
          <div className="label-container">
            <span className="label">Committee: </span>
            <span className="value-text">{com.committee}</span>
          </div>
        )}
        </p>
      </div>
    );
  });
}

function UnderConstructionPage() {
  return (
    <div className="underConstructionPage">
      <h1>This page is under construction.</h1>
    </div>
  );
}

const handleImageClick = (event) => {
  event.preventDefault();
};

export { ProfileCardItem, UnderConstructionPage, handleImageClick };
